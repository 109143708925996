<template>
  <mobile-screen :header="true" screen-class="add-level gray-bg icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="levels-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_levels-add-help-online',
          params: $route.params,
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: 'r_levels',
              params: $route.params,
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("level", "add-level", "add-level") }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit" v-if="$route.name == 'r_add-level'">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      ref="addLevelForm"
      :key="formKey"
      @submit="submitForm"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      novalidate
    >
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.has_translations }"
        >
          <div class="error-message" v-if="errors.has_translations">
            {{ errors.has_translations }}
          </div>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <div class="clebex-section-input-label">
                {{ displayLabelName("level", "add-level", "name") }}
              </div>
              <p class="text-label">{{ displayLevelName }}</p>
            </div>
            <span class="follow-up-icons">
              <router-link
                :to="{
                  name: 'r_add-level-translate-name',
                  params: $route.params,
                }"
                class="follow-up-icon-item"
              >
                <icon icon="#cx-men1-translations" />
              </router-link>
            </span>
          </div>
          <Field
            name="has_translations"
            as="input"
            type="hidden"
            rules="required"
            v-model="hasTranslations"
          />
        </li>
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="phoneLabelId">{{
                displayLabelName("level", "add-level", "telephone")
              }}</label>
              <Field
                id="phoneLabelId"
                name="phone"
                as="input"
                type="text"
                :placeholder="
                  `${displayLabelName('level', 'add-level', 'telephone')}`
                "
              />
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label
                class="clebex-section-input-label"
                for="googleMapsLabelId"
                >{{
                  displayLabelName("level", "add-level", "google-maps-link")
                }}</label
              >
              <Field
                id="googleMapsLabelId"
                name="google_maps_link"
                as="input"
                type="text"
                :placeholder="
                  `${displayLabelName(
                    'level',
                    'add-level',
                    'google-maps-link'
                  )}`
                "
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          :class="{ 'has-error': errors.address_line_1 }"
        >
          <span class="error-message" v-if="errors.address_line_1">
            {{ errors.address_line_1 }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="address1Id"
                >{{ displayLabelName("level", "add-level", "address1") }}
              </label>
              <Field
                id="address1Id"
                name="address_line_1"
                as="input"
                type="text"
                :placeholder="
                  `${displayLabelName('level', 'add-level', 'address1')}`
                "
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          :class="{ 'has-error': errors.address_line_2 }"
        >
          <span class="error-message" v-if="errors.address_line_2">
            {{ errors.address_line_2 }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="address2Id"
                >{{ displayLabelName("level", "add-level", "address2") }}
              </label>
              <Field
                id="address2Id"
                name="address_line_2"
                as="input"
                type="text"
                :placeholder="
                  `${displayLabelName('level', 'add-level', 'address2')}`
                "
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          :class="{ 'has-error': errors.city }"
        >
          <span class="error-message" v-if="errors.city">
            {{ errors.city }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="cityId"
                >{{ displayLabelName("level", "add-level", "city") }}
              </label>
              <Field
                id="cityId"
                name="city"
                as="input"
                type="text"
                :placeholder="
                  `${displayLabelName('level', 'add-level', 'city')}`
                "
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          :class="{ 'has-error': errors['zip'] }"
        >
          <span class="error-message" v-if="errors['zip']">
            {{ errors["zip"] }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="zipId"
                >{{ displayLabelName("level", "add-level", "zip-code") }}
              </label>
              <Field
                id="zipId"
                name="zip"
                as="input"
                type="text"
                :placeholder="
                  `${displayLabelName('level', 'add-level', 'zip-code')}`
                "
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          :class="{ 'has-error': errors.state }"
        >
          <span class="error-message" v-if="errors.state">
            {{ errors.state }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="stateId"
                >{{ displayLabelName("level", "add-level", "state") }}
              </label>
              <Field
                id="stateId"
                name="state"
                as="input"
                type="text"
                :placeholder="displayLabelName('level', 'add-level', 'state')"
              />
            </div>
          </div>
        </li>
      </ul>
      <ul class="clebex-item-section">
        <!---------------------------------->
        <!-- COUNTRY - START -->
        <!---------------------------------->
        <li class="clebex-item-section-item">
          <div
            @click="
              openCountry(country, 'r_add-level-select-country', $route.params)
            "
            class="clebex-item-content-wrapper"
            style="cursor: pointer;"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label
                class="clebex-section-input-label"
                for="stateId"
                style="cursor: pointer;"
                >{{ displayLabelName("level", "add-level", "country") }}
              </label>
              <p class="text-label" style="cursor: pointer;">
                {{ country ? country.name : "" }}
              </p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item" style="cursor: pointer;">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </div>
          <Field name="country_id" as="input" type="hidden" v-model="country" />
        </li>
        <!---------------------------------->
        <!-- COUNTRY - END -->
        <!---------------------------------->
        <!---------------------------------->
        <!-- TIMEZONE - START -->
        <!---------------------------------->

        <li v-if="!hideTimezone" class="clebex-item-section-item">
          <div
            @click="
              openTimezone(
                timezone,
                'r_add-level-select-timezone',
                $route.params
              )
            "
            class="clebex-item-content-wrapper"
            style="cursor: pointer;"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label
                class="clebex-section-input-label"
                style="cursor: pointer;"
                >{{ displayLabelName("level", "add-level", "timezone") }}</label
              >
              <p class="text-label ellipsis" style="cursor: pointer;">
                {{
                  timezone
                    ? timezone.name
                        .replace(/ \*/g, ", ")
                        .replace("UTC ", "UTC+")
                    : ""
                }}
              </p>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </div>
          <Field
            name="timezone_id"
            as="input"
            type="hidden"
            v-model="timezone"
          />
        </li>
        <!---------------------------------->
        <!-- TIMEZONE - END -->
        <!---------------------------------->
        <li class="clebex-item-section-item full-right-underline">
          <router-link
            :to="{
              name: 'r_add-level-select-working-hours',
              params: $route.params,
            }"
            class="clebex-item-content-wrapper cursor-pointer"
          >
            <div class="clebex-section-input">
              <label
                class="cursor-pointer"
                :class="
                  workingHoursFrom && workingHoursTo
                    ? 'clebex-section-input-label'
                    : 'label'
                "
                >{{
                  displayLabelName("level", "edit-level", "working-hours")
                }}</label
              >
              <p v-if="workingHoursFrom && workingHoursTo" class="text-label">
                {{ displayTime(workingHoursFrom) }} -
                {{ displayTime(workingHoursTo) }}
              </p>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
          <span v-if="workingHoursFrom && workingHoursTo">
            <Field
              name="working_hours_from"
              as="input"
              type="hidden"
              v-model="workingHoursFrom"
            />
            <Field
              name="working_hours_to"
              as="input"
              type="hidden"
              v-model="workingHoursTo"
            />
          </span>
        </li>
        <li class="clebex-item-section-item">
          <router-link
            :to="{ name: 'r_add-level-select-map', params: $route.params }"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input" v-if="selectedMap">
              <label class="clebex-section-input-label"
                >{{ displayLabelName("level", "add-level", "5d-maps") }}
              </label>
              <p class="text-label">
                {{ selectedMap.name }}
              </p>
            </div>
            <div class="label" v-else>
              {{ displayLabelName("level", "add-level", "5d-maps") }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
          <Field name="map_id" as="input" type="hidden" v-model="mapId" />
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import httpServiceAuth, { getLang } from "@/services/http-service";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { apiEndpoints } from "@/services/constants";
import { mapActions, mapGetters, mapState } from "vuex";
import { errorHandler } from "@/services/error-handler";
import countriesMixin from "@/services/mixins/countries/countries-mixin";
import timezonesMixin from "@/services/mixins/timezones/timezones-mixin";
import { DateTime } from "luxon";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "AddLevel",
  data() {
    return {
      levelType: 0,
      mode: "display",
      formKey: 0,
      helpSlug: "levels-add",
    };
  },
  created() {
    this.setSelectedCountry(null);
    this.setSelectedTimezone(null);
    this.selectMap(null);
    this.init();
  },
  computed: {
    ...mapState("user", ["company"]),
    ...mapState("country", ["selectedCountry"]),
    ...mapState("level", [
      "levels",
      "selectedMap",
      "levelTranslations",
      "workingHoursFrom",
      "workingHoursTo",
    ]),
    ...mapState("language", ["languages"]),
    ...mapState("country", ["selectedCountry"]),
    ...mapState("timezone", ["selectedTimezone"]),
    ...mapState("companyPreferences", ["preferences"]),
    ...mapGetters("level", ["parentLevels"]),
    ...mapGetters("settings", ["globalTimeFormat"]),
    timezone() {
      if (this.parentLevels && this.parentLevels.length) {
        return null;
      }
      if (this.selectedTimezone) {
        return this.selectedTimezone;
      } else {
        if (this.company && this.company.data) {
          return this.getTimezone(this.company.data.timezone_id);
        }
        return null;
      }
    },
    country() {
      if (this.selectedCountry) {
        return this.selectedCountry;
      } else if (this.parentLevels && this.parentLevels.length) {
        return this.getCountry(this.parentLevels[0].country_id);
      } else {
        if (this.company && this.company.data) {
          return this.getCountry(this.company.data.country_id);
        }
        return null;
      }
    },
    hideTimezone() {
      if (this.parentLevels && this.parentLevels.length) {
        return this.parentLevels.some((lvl) => lvl.timezone_id);
      }
      return null;
    },
    mapId() {
      return this.selectedMap && this.selectedMap.id;
    },
    translationFields() {
      const { languages, levelTranslations } = this;
      if (languages && languages.data && languages.data.length) {
        return languages.data.map((item, idx) => {
          if (levelTranslations && levelTranslations.length) {
            return {
              ...levelTranslations[idx],
              attributes: {
                ...levelTranslations[idx].attributes,
              },
            };
          } else {
            return {
              locale: item.locale,
              attributes: {
                name: "",
              },
            };
          }
        });
      }
      return null;
    },
    hasTranslations() {
      const { languages, levelTranslations } = this;
      if (languages && languages.data && languages.data.length) {
        return languages.data.length === levelTranslations.length;
      }
      return false;
    },
    displayLevelName() {
      const { levelTranslations } = this;
      const locale = getLang();
      if (levelTranslations && levelTranslations.length) {
        const filtered = levelTranslations.filter(
          (item) => item.locale === locale
        );
        if (filtered && filtered.length) {
          return filtered[0].attributes.name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions("level", [
      "getLevels",
      "getSublevels",
      "getLevel",
      "selectMap",
      "unsetMap",
      "setSelectedLevel",
    ]),
    ...mapActions("country", ["setSelectedCountry"]),
    ...mapActions("timezone", ["setSelectedTimezone"]),
    ...mapActions("companyPreferences", ["getCompanyPreferences"]),
    submitForm(values) {
      if (values) {
        this.submitCompanyData(values);
      }
    },
    init() {
      this.getCompanyPreferences("10").finally(() => {
        let workingHoursFromPreference = this.preferences.find(
          (el) => el.preference === "WORKING_HOURS_FROM"
        );
        let workingHoursToPreference = this.preferences.find(
          (el) => el.preference === "WORKING_HOURS_TO"
        );
        if (
          workingHoursFromPreference &&
          workingHoursFromPreference.preference_values &&
          workingHoursFromPreference.preference_values.length &&
          workingHoursToPreference &&
          workingHoursToPreference.preference_values &&
          workingHoursToPreference.preference_values.length
        ) {
          this.$store.commit(
            "level/setWorkingHoursFrom",
            workingHoursFromPreference.preference_values[0].value,
            {
              root: true,
            }
          );
          this.$store.commit(
            "level/setWorkingHoursTo",
            workingHoursToPreference.preference_values[0].value,
            {
              root: true,
            }
          );
        }
      });
    },
    submitCompanyData(values) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      if (this.levelTranslations.length) {
        values.translations = this.levelTranslations;
      }
      const { level_id } = this.$route.params;
      if (level_id) values.level_id = level_id;

      values.country_id = this.country.id;
      values.timezone_id = this.timezone ? this.timezone.id : null;

      httpServiceAuth
        .post(apiEndpoints.company.levels, values)
        .then((response) => {
          if (level_id) {
            this.getSublevels(level_id);
            this.getLevel(level_id);
          } else {
            this.getLevels();
          }
          this.setSelectedCountry(null);
          this.setSelectedTimezone(null);
          this.$store.commit("level/setLevelTranslations", [], {
            root: true,
          });
          this.$store.commit("level/setWorkingHoursFrom", null, { root: true });
          this.$store.commit("level/setWorkingHoursTo", null, { root: true });
          setTimeout(() => {
            if (this.formKey === 10) {
              this.formKey = 0;
            } else {
              this.formKey++;
            }
          });

          this.setSelectedLevel([response.data.data]);

          this.$router.push({
            name: "r_edit-level",
            params: this.$route.params,
          });
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
    displayTime(time) {
      if (!time) {
        return;
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
  },
  beforeUnmount() {
    this.unsetMap(false);
    this.$store.commit("level/setWorkingHoursFrom", null, { root: true });
    this.$store.commit("level/setWorkingHoursTo", null, { root: true });
    this.$store.commit("level/setLevelTranslations", [], {
      root: true,
    });
  },
  mixins: [countriesMixin, timezonesMixin, helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
};
</script>
